import React, { Fragment } from 'react';

import UploadIcon from '../../../../common/components/icons/UploadIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import { formatDate } from '../../../../common/utils/DateFunctions';

import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, onUpload, onDelete }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridState);

  return (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Dates'} columnField={'startDate'} 
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
        <th>Meet Host</th>
        <th>Course</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true ?
              <td colSpan="5">Loading...</td>
              : <td colSpan="5">No Meets</td>
            }
          </tr>
        ) : sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map(meet => (
            <tr key={meet.meetId}>
              <td>{meet.meetName}</td>
              <td>{formatDate(meet.startDate)} - {formatDate(meet.endDate)}</td>
              <td>{meet.orgUnitName}</td>
              <td>{meet.meetCourse}</td>
              <td>
                {onUpload !== undefined && meet.isPastOrCurrentMeet === true &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onUpload(e, meet)}>
                    <UploadIcon />
                  </button>
                } 
                {meet.hasResults === false ?
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, meet)}>
                    <DeleteIcon />
                  </button> : <Fragment />}
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="5">No Meets</td>
            </tr>
          )
      }
    </tbody>
  </table>
);
};

const SmallGrid = ({ state, gridState, onUpload, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Meets</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState) && gridState.length > 0
        ? gridState.map(meet => (
          <div className={global.SmallTableRow} key={meet.meetId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{meet.meetName}</span>&nbsp;
              {meet.hasResults === false ?
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, meet)}>
                  <DeleteIcon />
                </button> : <Fragment />}
              {onUpload !== undefined && meet.isPastOrCurrentMeet === true &&
                <button className={meet.hasResults === false ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onUpload(e, meet)}>
                  <UploadIcon />
                </button>
              }
            </div>
            <div className={['row', global.SmallTableRowInfo].join(' ')}>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{meet.meetName}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Dates</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(meet.startDate)} - {formatDate(meet.endDate)}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Host</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.orgUnitName}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Course</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetCourse}</div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meets</div>
            </div>
          </div>
        )
    }
  </div >
);

const TeamMeetGridWrite = ({ state, gridState, onUpload, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} gridState={gridState} onUpload={onUpload} onDelete={onDelete} />
    <SmallGrid state={state} gridState={gridState} onUpload={onUpload} onDelete={onDelete} />
  </Fragment>
);

export default TeamMeetGridWrite;