import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import TeamContextProvider from '../../state/team/TeamContextProvider';
import TeamFiltersContextProvider from '../../state/team/TeamFiltersContextProvider';
import SearchTeamContextProvider from '../../state/searchTeam/SearchTeamContextProvider';
import TeamMeetContextProvider from '../../state/team/teamMeet/TeamMeetContextProvider';
import TeamFacilityLinksContextProvider from '../../state/teamFacilityLinks/TeamFacilityLinksContextProvider';
import OtherOrgUnitChangeHistoryContextProvider from '../../state/otherOrgUnitChangeHistory/OtherOrgUnitChangeHistoryContextProvider';

import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import OrgUnitStaffContextProvider from '../../../common/state/orgUnitStaff/OrgUnitStaffContextProvider';
import OrgUnitAthleteContextProvider from '../../../common/state/orgUnitAthlete/OrgUnitAthleteContextProvider';
import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';
import FacilityContextProvider from '../../../common/state/facility/FacilityContextProvider';
import FacilityFiltersContextProvider from '../../../common/state/facility/FacilityFiltersContextProvider';
import SearchFacilityContextProvider from '../../../common/state/searchFacility/SearchFacilityContextProvider';
import PoolConfigurationContextProvider from '../../../common/state/poolConfiguration/PoolConfigurationContextProvider';
import OrgRoleContextProvider from '../../../common/state/orgRole/OrgRoleContextProvider';
import MeetContextProvider from '../../../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../../../common/state/meet/MeetFiltersContextProvider';
import MeetSessionsContextProvider from '../../../common/state/meetSessions/MeetSessionsContextProvider';
import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import MeetResultsStatusContextProvider from '../../../common/state/meet/meetResultsUploadStatus/MeetResultsStatusContextProvider';


import TeamContextView from './TeamContextView';

import TeamSearch from './teamSearch/TeamSearch';
import TeamAdd from './teamAdd/TeamAdd';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import TeamStaffWrite from './teamStaff/TeamStaffWrite';
import TeamStaffReadOnly from './teamStaff/TeamStaffReadOnly';
import TeamStaffDetail from './teamStaff/TeamStaffDetail';
import TeamAthleteRosterWrite from './teamAthleteRoster/teamAthleteRosterWrite/TeamAthleteRosterWrite';
import TeamAthleteRosterReadOnly from './teamAthleteRoster/teamAthleteRosterRead/TeamAthleteRosterReadOnly';
import TeamAthleteDetail from './teamAthleteRoster/teamAthleteRosterWrite/TeamAthleteWriteDetail';
import TeamMeetWrite from './teamMeet/TeamMeetWrite';
import TeamMeetReadOnly from './teamMeet/TeamMeetReadOnly';
import MeetAdd from './teamMeet/meetAdd/MeetAdd';
import MeetSessions from './teamMeet/meetSessions/MeetSessions';
import MeetSessionsDetail from './teamMeet/meetSessions/MeetSessionsDetail';
import MeetUpload from './teamMeet/meetUpload/MeetUpload';
import TeamFacilityWrite from './teamFacility/TeamFacilityWrite';
import TeamFacilityReadOnly from './teamFacility/TeamFacilityReadOnly';
import TeamFacilitySearch from './teamFacility/TeamFacilitySearch';
import TeamFacilityDetails from './teamFacility/TeamFacilityDetails';
import TeamFacilityAdd from './teamFacility/TeamFacilityAdd';
import TeamFacilityPoolDetail from './teamFacility/TeamFacilityPoolDetail';
import TeamChanges from './teamChanges/TeamChanges';
import TeamReporting from './teamReporting/TeamReporting';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import TeamAthleteRosterAdvancedWrite from './teamAthleteRoster/teamAthleteRosterAdvancedWrite/TeamAthleteRosterAdvancedWrite';
import TeamAthleteAdvancedWriteDetail from './teamAthleteRoster/teamAthleteRosterAdvancedWrite/TeamAthleteAdvancedWriteDetail';
import OtherTeamAthleteRoster from './teamAthleteRoster/otherTeamAthleteRoster/OtherTeamAthleteRoster';

const ContextRoutes = ({ navRoutes }) => (
  <TeamContextView>
    <Routes>
      <Route path={navRoutes['TEAM_STAFF']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_STAFF']}
            editElement={<TeamStaffWrite />}
            readOnlyElement={<TeamStaffReadOnly />} />
        }
      />
      <Route path={navRoutes['TEAM_STAFF_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_STAFF_DETAIL']}
            editElement={<TeamStaffDetail />} />
        }
      />
      <Route path={navRoutes['TEAM_ATHLETEROSTER_ADVANCED']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_ATHLETEROSTER_ADVANCED']}
            editElement={<TeamAthleteRosterAdvancedWrite />} />
        }
      />
      <Route path={navRoutes['TEAM_DOWNLOADOTHERTEAMROSTER']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_DOWNLOADOTHERTEAMROSTER']}
            editElement={<OtherTeamAthleteRoster />} />
        }
      />
      <Route path={navRoutes['TEAM_ATHLETEROSTER_ADVANCED_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_ATHLETEROSTER_ADVANCED_DETAIL']}
            editElement={<TeamAthleteAdvancedWriteDetail />} />
        }
      />
      <Route path={navRoutes['TEAM_ATHLETEROSTER']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_ATHLETEROSTER']}
            editElement={<TeamAthleteRosterWrite />}
            readOnlyElement={<TeamAthleteRosterReadOnly />} />
        }
      />
      <Route path={navRoutes['TEAM_ATHLETEROSTER_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_ATHLETEROSTER_DETAIL']}
            editElement={<TeamAthleteDetail />} />
        }
      />
      <Route path={navRoutes['TEAM_MEET']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_MEET']}
            editElement={<TeamMeetWrite />}
            readOnlyElement={<TeamMeetReadOnly />} />
        }
      />
      <Route path={navRoutes['TEAM_MEET_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_MEET_ADD']}
            editElement={<MeetAdd />} />
        }
      />
      <Route path={navRoutes['TEAM_MEET_ADD_SESSIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_MEET_ADD_SESSIONS']}
            editElement={<MeetSessions />} />
        }
      />
      <Route path={navRoutes['TEAM_MEET_ADD_SESSIONS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_MEET_ADD_SESSIONS_DETAIL']}
            editElement={<MeetSessionsDetail />} />
        }
      />
      <Route path={navRoutes['TEAM_MEET_UPLOAD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_MEET_UPLOAD']}
            editElement={<MeetUpload />} />
        }
      />
      <Route path={navRoutes['TEAM_FACILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_FACILITY']}
            editElement={<TeamFacilityWrite />}
            readOnlyElement={<TeamFacilityReadOnly />} />
        }
      />
      <Route path={navRoutes['TEAM_FACILITY_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_FACILITY_SEARCH']}
            editElement={<TeamFacilitySearch />} />
        }
      />
      <Route path={navRoutes['TEAM_FACILITY_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_FACILITY_DETAIL']}
            editElement={<TeamFacilityDetails />} />
        }
      />
      <Route path={navRoutes['TEAM_FACILITY_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_FACILITY_ADD']}
            editElement={<TeamFacilityAdd />} />
        }
      />
      <Route path={navRoutes['TEAM_FACILITY_POOL_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_FACILITY_POOL_DETAIL']}
            editElement={<TeamFacilityPoolDetail />} />
        }
      />
      <Route path={navRoutes['TEAM_TEAM_CHANGES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_TEAM_CHANGES']}
            editElement={<TeamChanges />} />
        }
      />
      <Route path={navRoutes['TEAM_REPORTING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_REPORTING']}
            editElement={<TeamReporting />} />
        }
      />
    </Routes>
  </TeamContextView>
);

const TeamRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['TEAM_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_SEARCH']}
            editElement={<TeamSearch />} />
        }
      />
      <Route path={navRoutes['TEAM_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TEAM_ADD']}
            editElement={<TeamAdd />} />
        }
      />
      <Route path={navRoutes['MY_TEAM_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_TEAM_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['TEAM_SEARCH']?.route} replace />} />
    </Routes>
  );
};

const Team = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <TeamContextProvider>
        <MeetResultsStatusContextProvider>
          <OtherOrgUnitChangeHistoryContextProvider>
            <TeamFiltersContextProvider>
              <TeamFacilityLinksContextProvider>
                <SearchTeamContextProvider>
                  <FacilityContextProvider>
                    <PersonGeneralContextProvider>
                      <OrgRoleContextProvider>
                        <OrgUnitStaffContextProvider>
                          <OrgUnitAthleteContextProvider>
                            <TeamMeetContextProvider>
                              <FacilityContextProvider>
                                <FacilityFiltersContextProvider>
                                  <SearchFacilityContextProvider>
                                    <PoolConfigurationContextProvider>
                                      <MeetContextProvider>
                                        <MeetFiltersContextProvider>
                                          <MeetSessionsContextProvider>
                                            <TimeContextProvider>
                                              <TimeFiltersContextProvider>
                                                <TimesOrganizationContextProvider>
                                                  <SessionContextProvider>
                                                    <TeamRoutes />
                                                  </SessionContextProvider>
                                                </TimesOrganizationContextProvider>
                                              </TimeFiltersContextProvider>
                                            </TimeContextProvider>
                                          </MeetSessionsContextProvider>
                                        </MeetFiltersContextProvider>
                                      </MeetContextProvider>
                                    </PoolConfigurationContextProvider>
                                  </SearchFacilityContextProvider>
                                </FacilityFiltersContextProvider>
                              </FacilityContextProvider>
                            </TeamMeetContextProvider>
                          </OrgUnitAthleteContextProvider>
                        </OrgUnitStaffContextProvider>
                      </OrgRoleContextProvider>
                    </PersonGeneralContextProvider>
                  </FacilityContextProvider>
                </SearchTeamContextProvider>
              </TeamFacilityLinksContextProvider>
            </TeamFiltersContextProvider>
          </OtherOrgUnitChangeHistoryContextProvider>
        </MeetResultsStatusContextProvider>
      </TeamContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default Team;